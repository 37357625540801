import React, { useState, useEffect } from 'react';
import ModalSucess from './ModalSucess';

const Hero = () => {
    const [departure, setDeparture] = useState('');
    const [arrival, setArrival] = useState('');
    const [phone, setPhone] = useState('');
    const [date, setDate] = useState('');
    const [model_open, setOpenModal] = useState(false);
    const [min_date, setMinDate] = useState('');

    const handleDateChange = (e) => {
        const value = e.target.value;



        setDate(value); // Save the value directly if it's in the correct format
    };

    useEffect(() => {
        const loadGoogleMapsAPI = () => {

            const departureInput = document.getElementById('departure');
            const arrivalInput = document.getElementById('arrival');

            if (window.google) {
                const departureAutocomplete = new window.google.maps.places.Autocomplete(departureInput);
                const arrivalAutocomplete = new window.google.maps.places.Autocomplete(arrivalInput);

                departureAutocomplete.addListener('place_changed', () => {
                    const place = departureAutocomplete.getPlace();
                    setDeparture(place.formatted_address || departureInput.value); // Ensure the address is saved
                });

                arrivalAutocomplete.addListener('place_changed', () => {
                    const place = arrivalAutocomplete.getPlace();
                    setArrival(place.formatted_address || arrivalInput.value); // Ensure the address is saved
                });
            };
        }



        loadGoogleMapsAPI();

        initialDates();

    }, []);

    function initialDates() {
        const now = new Date();

        const options = { timeZone: 'Europe/Paris', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
        const dateInFranceParts = new Intl.DateTimeFormat('fr-FR', options).formatToParts(now);

        const year = dateInFranceParts.find(part => part.type === 'year').value;
        const month = dateInFranceParts.find(part => part.type === 'month').value;
        const day = dateInFranceParts.find(part => part.type === 'day').value;
        const hour = dateInFranceParts.find(part => part.type === 'hour').value;
        const minute = dateInFranceParts.find(part => part.type === 'minute').value;

        const formattedDateString = `${year}-${month}-${day}T${hour}:${minute}`;

        setMinDate(formattedDateString);
        setDate(formattedDateString);
    }

    function formateDateFr(date_str) {
        const date = new Date(date_str);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        };

        const formatter = new Intl.DateTimeFormat('fr-FR', options);
        const formattedDate = formatter.format(date);

        return formattedDate;
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            departure,
            arrival,
            phone,
            'date': formateDateFr(date)
        };
        // http://localhost:8080
        fetch('/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),

        }).then(response => response.json())
            .then(data => {
                setOpenModal(true);
                initialDates();
                setArrival('');
                setDeparture('');
                setPhone('');
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    return (
        <>
            <ModalSucess open_modal={model_open} setOpenModal={setOpenModal} />
            <div className="relative">
                <div className="absolute inset-0">
                    <img
                        src="./img/bg-1.1.jpg" // Replace with your image URL
                        alt="Paris background"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0" style={{ backgroundColor: 'rgba(56, 53, 53, .708)' }}></div>
                </div>

                {/* Content */}
                <div className="relative z-10 gap-14 flex flex-col md:flex-row justify-between items-center px-6 md:px-12 py-12">
                    <div className="text-white  hidden md:block md:w-1/2 mb-0">
                        <h1 className="text-lg leading-snug font-bold md:text-5xl md:leading-tight">Bienvenue sur <span className="bg-red-600 px-2 py-1 rounded-lg">Chauffeur Paris</span></h1>
                        <p className="mt-4 text-lg ">
                            Votre solution de réservation de taxi à Paris. Simplifiez vos déplacements dans la
                            Ville Lumière grâce à notre plateforme simple et fiable. Que vous vous rendiez à la
                            Tour Eiffel, que vous exploriez le Louvre ou que vous parcouriez les charmantes rues
                            de Montmartre, nous vous garantissons un trajet fluide et sans stress. En quelques clics,
                            vous pouvez réserver votre taxi et profiter d'un trajet en douceur au cœur de Paris.
                            Laissez-nous gérer le trafic pendant que vous vous imprégnez de la beauté de la capitale
                            française.
                        </p>
                    </div>

                    {/* Right side form */}
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full md:w-1/3 md:mt-0">
                        <h1 className=" text-xl text-center mb-2 font-sans leading-snug font-bold md:text-2xl md:leading-tight"><span className="bg-red-600 text-white px-2 py-1 rounded-lg">Chauffeur Paris</span></h1>
                        <hr className="border-red-800 dark:border-white mb-3 border-4" />
                        {/* <div className="bg-gray-800 p-2 mb-4 rounded-md">
                            <h2 className="text-white text-center font-bold">Planifiez votre trajet en quelques secondes</h2>
                        </div> */}

                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2" htmlFor="departure">Adresse de départ :</label>
                                <input
                                    type="text"
                                    id="departure"
                                    value={departure}
                                    onChange={(e) => setDeparture(e.target.value)}
                                    placeholder="Paris 11" // Google Maps API will autocomplete
                                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2" htmlFor="arrival">Adresse d'arrivée :</label>
                                <input
                                    type="text"
                                    id="arrival"
                                    value={arrival}
                                    onChange={(e) => setArrival(e.target.value)}
                                    placeholder="Orly" // Google Maps API will autocomplete
                                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                    required

                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2" htmlFor="date">Date de réservation :</label>
                                <input
                                    type="datetime-local"
                                    id="date"
                                    value={date}
                                    onChange={handleDateChange}
                                    min={min_date}
                                    max="2099-12-31T23:59"
                                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                    required

                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2" htmlFor="phone">Numéro de téléphone :</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    placeholder="+33"
                                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                    required

                                />
                            </div>

                            <button
                                type="submit"
                                className="w-full mb-2 bg-gray-600 text-white font-bold text-xl font-sans p-3 rounded hover:bg-gray-700 transition duration-300 ease-in-out"
                            >
                                Réserver maintenant
                            </button>
                            <a
                                href="tel:0185091394"
                                className="w-full bg-red-600 text-white font-bold text-xl font-sans p-3 rounded flex items-center justify-center hover:bg-red-700 transition duration-300 ease-in-out"
                            >Appeler directement
                            </a>
                        </form>
                    </div>
                </div>
            </div >
        </>

    );
};

export default Hero;
