import React, { } from 'react';
import { FaHeadset, } from 'react-icons/fa';

const Header = () => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggleMenu = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <header className="bg-white lg:bg-white-800 p-4 sticky top-0 z-50 border-b-4">
      <nav className="container mx-auto flex justify-between items-center">

        <div className="flex-shrink-0">
          <a href="/" className="text-lg leading-snug font-sans font-bold md:text-2md md:leading-tight">
            Chauffeur <span className="bg-red-600 px-2 py-1 text-white font-sans rounded-lg">Paris</span>
          </a>
        </div>



        <ul className={`hidden lg:flex space-x-6 text-white items-center`}>
          <li><a href="/" className="hover:underline text-black font-sans font-bold">Accueil</a></li>
          <li><a href="#Services" className="hover:underline text-black font-sans font-bold">Services</a></li>
          <li><a href="#Prices" className="hover:underline text-black font-sans font-bold">Tarif</a></li>
          <li><a href="#Contact" className="hover:underline text-black font-sans font-bold">Contact</a></li>
        </ul>

        <div className="flex items-center space-x-4">
          <a href="tel:0185091394" className="text-black lg:text-black font-bold text-lg flex items-center">
            <FaHeadset className="mr-2 text-2xl" />
            <span className="font-bold text-xl pr-1.5 text-black font-sans font-bold"> | </span>
            01 85 09 13 94
          </a>
          <img src="./img/FRflag.png" alt="French Flag" className="h-6 w-auto border border-black rounded-2xl" />
        </div>
      </nav>
    </header>
  );
};

export default Header;
