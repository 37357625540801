// src/components/LegalNotice.js
import React from 'react';

const LegalNotice = () => {
  return (
    <div className="container mx-auto p-10 mb-6 mt-6" id="Legal">
      <h1 className="text-3xl font-bold mb-4">Mentions Légales</h1>

      <h2 className="text-2xl font-bold mt-6 mb-2">1. Informations sur l'entreprise</h2>
      <p>
        <strong>Raison sociale :</strong> RESERVATIONVTC LTD<br />
        <strong>Adresse du siège social :</strong> 71-75 Shelton Street, Covent Garden, Londres, WC2H 9JQ, ROYAUME-UNI<br />
        <strong>Email de contact :</strong> <a href="mailto:contact@taxiparis-reservationvtc.com" className="text-blue-500">contact@taxiparis-reservationvtc.com</a><br />
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">2. Hébergement du site</h2>
      <p>
        <strong>Hébergeur :</strong> Hostinger<br />
        <strong>Adresse :</strong> 61 Lordou Vironos Street, 6023 Larnaca, Chypre<br />
        <strong>Téléphone :</strong> <a href="tel:+37064503378" className="text-blue-500">+370 645 03378</a><br />
        <strong>Site web :</strong> <a href="https://www.hostinger.fr" target="_blank" rel="noopener noreferrer" className="text-blue-500">https://www.hostinger.fr</a>
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">3. Propriété Intellectuelle</h2>
      <p>
        Tous les contenus présents sur ce site, y compris les textes, images, graphiques et logos, sont la propriété de RESERVATIONVTC LTD ou de ses concédants. Toute utilisation ou reproduction non autorisée de ces contenus est interdite, sauf autorisation expresse de la société.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">4. Limitation de Responsabilité</h2>
      <p>
        RESERVATIONVTC LTD ne peut être tenue responsable des dommages directs ou indirects pouvant résulter de l'accès ou de l'utilisation du site, y compris des virus ou de la perte de données. La société se réserve le droit de modifier le contenu du site à tout moment sans préavis.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">5. Droit Applicable</h2>
      <p>
        Ce site web et son contenu sont régis par les lois du Royaume-Uni. Tout litige découlant de l'utilisation de ce site sera soumis à la juridiction des tribunaux du Royaume-Uni.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">6. Informations de Contact</h2>
      <p>
        Pour toute question légale, veuillez nous contacter à l'adresse suivante : <a href="mailto:contact@taxiparis-reservationvtc.com" className="text-blue-500">contact@taxiparis-reservationvtc.com</a>.
      </p>
    </div>
  );
};

export default LegalNotice;
