import React from 'react';

const PricingSection = () => {
    return (
        <section className="bg-white py-1" id="Prices">
            <div className="px-[2rem] py-16 md:py-8 md:px-8 lg:px-36">
                <div className="px-1">
                    <h2 className="text-4xl text-gray-800 text-center font-bold mb-4 uppercase">Les Forfaits aéroports</h2>
                </div>
                <div className="xl:px-32 lg:px-16 px-1">
                    <p className="text-lg text-center text-gray-800">
                        Applicables aux trajets Paris - Orly et Roissy CDG - Paris.
                    </p>
                </div>
            </div>

            <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 px-20 pb-20 px-[3rem] md:px-8 lg:px-48">
                <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
                    <h3 className="text-xl font-semibold mb-4">Tous les modes de paiement acceptés</h3>
                    <div className="flex justify-center space-x-4">
                        <img src="./img/visa.png" alt="Visa" className="w-16 h-auto" />
                        <img src="./img/mastercard.png" alt="Mastercard" className="w-16 h-auto" />
                        <img src="./img/paypal.png" alt="PayPal" className="w-16 h-auto" />
                    </div>
                </div>
                <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
                    <h3 className="text-xl font-semibold mb-4">Prix moyen pour Roissy CDG</h3>
                    <p className="text-4xl font-bold text-red-500">60€ *</p>
                    <small className="text-gray-600">*Tarif début 2024</small>
                </div>
                <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
                    <h3 className="text-xl font-semibold mb-4">Prix moyen pour Orly</h3>
                    <p className="text-4xl font-bold text-red-500">40€ *</p>
                    <small className="text-gray-600">*Tarif début 2024</small>
                </div>
            </div>
        </section>
    );
};

export default PricingSection;
