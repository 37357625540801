import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faCreditCard, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

const OurServices = () => {
  useEffect(() => {
    const counters = document.querySelectorAll('[id^=counter]');
    counters.forEach(counter => {
      let targetText = counter.innerText.replace('+', '').replace('%', '').trim();
      let target = +targetText;
      let count = 0;

      const updateCounter = () => {
        count += Math.ceil(target / 100);
        if (count >= target) {
          counter.innerText = counter.id === 'counter3' ? `${target} %` : `+${target}`;
        } else {
          counter.innerText = counter.id === 'counter3' ? `${count} %` : `+${count}`;
          setTimeout(updateCounter, 30);
        }
      };
      updateCounter();
    });
  }, []);

  return (
    <div>
    <div
      className="relative bg-cover bg-left-center md:bg-center py-20"
      id="Services"
      style={{ backgroundImage: `url('./img/bg-2.jpg')` }}
    >
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <div className="relative container mx-auto text-white">
        <h2 className="text-center text-4xl font-bold mb-12">NOS SERVICES</h2>

        {/* Services Section */}
        <div className="flex flex-col md:items-center space-y-12 px-10">
          {/* First service */}
          <div className="flex md:justify-center items-center space-x-4 w-full max-w-xl">
            <FontAwesomeIcon icon={faUserTie} className="text-6xl md:text-8xl " />
            <div className="flex flex-col justify-center">
              <h4 className="font-bold uppercase mb-2 text-sm md:text-base">Chauffeurs professionnels et véhicules de luxe</h4>
              <p className="text-xs md:text-sm">
              Nous travaillons en partenariat avec des chauffeurs agréés pour offrir un service haut de gamme et un
              confort inégalé à Paris.
              </p>
            </div>
          </div>

          {/* Second service */}
          <div className="flex md:justify-center items-center space-x-4 w-full max-w-xl">
            <FontAwesomeIcon icon={faCreditCard} className="text-5xl md:text-7xl" />
            <div className="flex flex-col justify-center">
              <h4 className="font-bold uppercase mb-2 text-sm md:text-base">Paiement sécurisé (carte/espèces)</h4>
              <p className="text-xs md:text-sm">
                La sécurité de vos paiements est notre priorité absolue. Que vous payiez par carte ou en espèces, vous
                pouvez être sûr de la sécurité de chaque transaction.
              </p>
            </div>
          </div>

          {/* Third service */}
          <div className="flex md:justify-center items-center space-x-4 w-full max-w-xl">
            <FontAwesomeIcon icon={faCalendarCheck} className="text-6xl md:text-8xl" />
            <div className="flex flex-col justify-center">
              <h4 className="font-bold uppercase mb-2  text-sm md:text-base">Annulation gratuite</h4>
              <p className="text-xs md:text-sm ">
                Changement de plan ? Pas de soucis. Annulez votre réservation gratuitement jusqu'à 24 heures avant votre
                transfert prévu.
              </p>
            </div>
          </div>
        </div>

      </div>
      
    </div>
    {/* Stats Section */}
    <div className="bg-neutral-800 p-4 md:p-8">
        <div className="mt-10 mb-10 text-white grid grid-cols-3 gap-4 md:gap-16 px-2 md:px-8 lg:px-64">
          <div className="text-center">
            <h3 className="text-xl md:text-4xl font-bold mb-2 border-2 border-white px-4 py-4 md:py-6 rounded-lg" id="counter1">
              + 680
            </h3>
            <p className="text-lg md:text-2xl">Clients</p>
          </div>

          <div className="text-center">
            <h3 className="text-xl md:text-4xl font-bold mb-2 border-2 border-white px-4 py-4 md:py-6 rounded-lg" id="counter2">
              + 973
            </h3>
            <p className="text-lg md:text-2xl">Trajets</p>
          </div>

          <div className="text-center">
            <h3 className="text-xl md:text-4xl font-bold mb-2 border-2 border-white px-4 py-4 md:py-6 rounded-lg" id="counter3">
              100
            </h3>
            <p className="text-lg md:text-2xl">Clients satisfaits</p>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default OurServices;
