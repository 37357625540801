import React from 'react';


const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white pt-8">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 text-left px-8">

        <div>
          <a href="/" className="text-lg leading-snug font-sans font-bold md:text-2md md:leading-tight">
            Chauffeur <span className="bg-red-600 px-2 py-1 text-white font-sans rounded-lg">Paris</span>
          </a>
          <p className="text-gray-400 mt-5">
            Taxi Reservation est votre service de réservation de taxi à Paris, simplifiant vos besoins de transport.
          </p>
        </div>

        <div>
          <h3 className="font-bold text-lg mb-4">Légal</h3>
          <ul>
            <li><a href="/legal-notice" className="text-gray-400 hover:text-white mb-2">Mentions Légales</a></li>
            <li><a href="/terms-and-conditions" className="text-gray-400 hover:text-white mb-2">Conditions Générales</a></li>
            <li><a href="/privacy-policy" className="text-gray-400 hover:text-white mb-2">Politique de Confidentialité</a></li>
          </ul>
        </div>

        <div>
          <h3 className="font-bold text-lg mb-4">Menu</h3>
          <ul>
            <li><a href="/" className="text-gray-400 hover:text-white mb-2">Accueil</a></li>
            <li><a href="#Services" className="text-gray-400 hover:text-white mb-2">Services</a></li>
            <li><a href="#Prices" className="text-gray-400 hover:text-white mb-2">Tarifs</a></li>
            <li><a href="#Contact" className="text-gray-400 hover:text-white mb-2">Contact</a></li>
          </ul>
        </div>

        <div>
          <h3 className="font-bold text-lg mb-4">Horaires d'ouverture</h3>
          <p className="text-gray-400">Tous les jours, 24h/24</p>
        </div>
      </div>

      <div className="bg-gray-800 text-center py-4 mt-8 px-4">
        <p className="text-gray-500 text-sm">© 2024 Chauffeur Paris. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
