// src/components/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-10 mb-6 mt-6" id="Privacy">
      <h1 className="text-3xl font-bold mb-4">Politique de Confidentialité</h1>
      <p>Votre confidentialité est importante pour nous. Cette Politique de Confidentialité explique comment nous collectons, utilisons et protégeons vos informations.</p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Informations sur l'entreprise</h2>
      <p>
        <strong>Raison sociale :</strong> RESERVATIONVTC LTD<br />
        <strong>Adresse :</strong> 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, ROYAUME-UNI<br />
        <strong>Contact :</strong> <a href="mailto:contact@taxiparis-reservationvtc.com" className="text-blue-500">contact@taxiparis-reservationvtc.com</a>
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Informations sur l'hébergement du site web</h2>
      <p>
        <strong>Fournisseur d'hébergement :</strong> Hostinger<br />
        <strong>Adresse :</strong> 61 Lordou Vironos Street, 6023 Larnaca, Chypre<br />
        <strong>Téléphone :</strong> <a href="tel:+37064503378" className="text-blue-500">+370 645 03378</a><br />
        <strong>Site web :</strong> <a href="https://www.hostinger.fr" target="_blank" rel="noopener noreferrer" className="text-blue-500">https://www.hostinger.fr</a>
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Collecte et Utilisation des Données</h2>
      <p>
        Nous collectons des informations personnelles nécessaires au bon fonctionnement de nos services. Cela inclut les informations que vous fournissez lors de la réservation ou en nous contactant. Nous ne partageons ni ne vendons ces informations à des tiers, sauf si la loi l'exige.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Sécurité</h2>
      <p>
        Nous prenons la sécurité de vos informations personnelles au sérieux et utilisons des mesures raisonnables pour les protéger. Toutefois, aucune méthode de transmission sur Internet ou de stockage électronique n'est sécurisée à 100 %.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
